import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SocketIOService } from 'src/app/socket-io/socket-io.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private getChatURL = '';

  constructor(
    private http: HttpClient,
    private socketService: SocketIOService
  ) {
   
  }


  public getMeetingChat(meetingId): any {
    return this.http.get(`${environment.apiURL}/chat/get-meeting-chat/${meetingId}`).toPromise();
  }

  public sendMessage(chatId,content){
    this.socketService.socketIO.emit('sendMessage',{chatId,...content});
  }

  public getMessages = () => {
    return Observable.create((observer) => {
      this.socketService.socketIO.on('new-message', (message) => {
            observer.next(message);
        });
    });
}
 

}
