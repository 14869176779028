import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';

const agoraConfig: AgoraConfig = {
  AppID: 'a1fafae6dc964024b8f034f69a69f42c',
};

// const config = new AuthServiceConfig([
//   {
//   id: GoogleLoginProvider.PROVIDER_ID,
//   provider: new GoogleLoginProvider(“YOUR-GOOGLE-CLIENT-ID”)
//   }
//   ]);
//  export function provideConfig() {
//   return config;
//   }
import { AngularAgoraRtcModule, AgoraConfig } from 'angular-agora-rtc';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';

import {
  AngularFirestore,
  AngularFirestoreModule,
} from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
// import { PrepareMettingComponent } from './pages/prepare-metting/prepare-metting.component';
import { ChatComponent } from './components/chat/chat.component';
import { ChatMessageComponent } from './components/chat/chat-message/chat-message.component';
import { ChatControlsComponent } from './components/chat/chat-control/chat-control.component';
import { PreSessionScreenComponent } from './pages/agora/pre-session-screen/pre-session-screen.component';
import { StreamViewComponent } from './pages/agora/stream-view/stream-view.component';
import { AgoraComponent } from './pages/agora/agora.component';
import { CreateMeetingDialogComponent } from './components/create-meeting-dialog/create-meeting-dialog.component';
import { ControlsComponent } from './components/controls/controls.component';
import { AuthService } from './services/auth/auth-service.service';
import { AuthGuard } from './services/guard/auth.guard';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { MeetingService } from './services/meeting/meeting.service';
import { AgoraRtcService } from './services/agora/agora-rtc.service';
import { ChatService } from './services/chat/chat.service';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AgoraComponent,
    PreSessionScreenComponent,
    ChatComponent,
    ChatMessageComponent,
    ChatControlsComponent,
    StreamViewComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    CreateMeetingDialogComponent,
    ControlsComponent,
  ],
  imports: [
    // NgSelectModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    RouterModule,
    // NgbModule,
    // NgxPaginationModule,
    MatCardModule,
    MatFormFieldModule,
    MatToolbarModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyC9YR-5XvpBy-SElurNvje7YC-O8IuTg94',
    //   libraries: ['places'],
    // }),
    // GooglePlaceModule,
    SocialLoginModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDividerModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatSliderModule,
    MatGridListModule,
    MatInputModule,
    MatListModule,
    MatChipsModule,
    MatMenuModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    MatBadgeModule,
    ClipboardModule,
    NgxUiLoaderModule
  ],
  providers: [
    // LoginComponent,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true,
    // },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '176044220879-rraoa54ekt4aadk19q3ipncuu5o36kua.apps.googleusercontent.com',
            ),
          },
          // {
          //   id: FacebookLoginProvider.PROVIDER_ID,
          //   provider: new FacebookLoginProvider('1917898955053079'),
          // },
        ],
      } as SocialAuthServiceConfig,
    },
    AuthService, 
    AuthGuard,
    MeetingService,
    AgoraRtcService,
    ChatService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

