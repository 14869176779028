import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { RemoteUser } from '../../shared/models/remoteUser';
import { StorageServiceService } from '../../services/storage/storage-service.service';
import { PreSessionScreenComponent } from './pre-session-screen/pre-session-screen.component';
import { ToastrService } from 'ngx-toastr';
import { AgoraRtcService } from 'src/app/services/agora/agora-rtc.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { Media } from 'src/app/shared/_classes/Media';
import { MeetingService } from 'src/app/services/meeting/meeting.service';
import { SocketIOService } from 'src/app/socket-io/socket-io.service';
import { ChatService } from 'src/app/services/chat/chat.service';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-agora',
  templateUrl: './agora.component.html',
  styleUrls: ['./agora.component.scss'],
  styles: ['body{ margin-top: 0 !important;}'],
})
export class AgoraComponent {
  mediaData: any = [];
  sideMediaData: any = [];
  audioDevices: [];
  videoDevices: [];
  userDetails: {
    userId: String;
    channelId: String;
    isScreenStream: boolean;
    screenId?: String;
    role: String
  };

  focusedStreamId = null;
  currentArtistId = null;
  isArtistk = false;
  artistDetails: RemoteUser;
  isScreenShared = false;
  isLocalScreen = false;
  isGalleryView = false;
  handleNetwork = null;
  networkState = null;
  sessionType = '';
  loginDetails: any = {};
  artistId = '';
  recordingInProgress = false;
  chatOpened = false;
  sideBarOpened = false;
  localStreamId = null;
  MAX_VIDEO_TILES = 10;
  viewColSpanMin = 2; // 2
  viewColSpanMax = 8; // 16
  viewRowSpanMin = 1; // 2
  viewRowSpanMax = 3; // 16
  gridRowSpan = 4;
  gridColSpan = 16;
  sideGridRowSpan = 1;
  sideGridColSpan = 1;
  messages = [];
  newMessageCount = 0;
  userData = {};
  recordingInterval = null;

  constructor(
    public agoraRTC: AgoraRtcService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public storage: StorageServiceService,
    private router: Router,
    private fbService: FirebaseService,
    private meetingService: MeetingService,
    private toastr: ToastrService,
    private chatService: ChatService,
    private socketService: SocketIOService,
    private authService: AuthService,
    private ngxLoader: NgxUiLoaderService
  ) {
    this.userDetails = {
      userId: "",
      channelId: "",
      isScreenStream: false,
      screenId: '',
      role: ''
    };
  }

  ngOnInit() {
    const init = async () => {
      this.ngxLoader.start();
      await this.agoraRTC.initSession();
      this.loginDetails = this.authService.getAuthData();
      this.registerAgoraEvents();
      await this.callInitSession();
      this.ngxLoader.stop();
    };

    this.agoraRTC.setupDoneTrigger.subscribe(flag => {
      init();
    });
  }

  callInitSession() {
    return new Promise((res, rej) => {
      this.route.paramMap.subscribe(async (params) => {
        await this.initiateSession(params);
        res(true);
      });
    });
  }

  async initiateSession(params) {
    try {
      let meetingDetails = JSON.parse(atob(params.get('meetingId')));

      const role = meetingDetails.role == '1' ? 'host' : 'attendee';
      this.userDetails = {
        userId: this.loginDetails.user.uid,
        channelId: meetingDetails.id,
        isScreenStream: false,
        role
      };

      this.agoraRTC.setUserCredentials(this.userDetails);
      await this.checkMeetingValidation();

      // TODO: when insert, save in localstore
      // if not exits insert participants
      let userDetails = JSON.parse(localStorage.getItem('participant'));

      if (!userDetails || userDetails && userDetails.channelId !== this.userDetails.channelId) {
        // todo : rename insertMeetingParticipants -> addMeetingParticipants
        userDetails = await this.meetingService.insertMeetingParticipants({ ...this.userDetails, inTime: Date.now() });
        if (!userDetails.status) {
          this.toastr.warning(userDetails.error);
        }
        userDetails = userDetails.data;
        localStorage.setItem('participant', JSON.stringify(userDetails));
      }
      this.agoraRTC.setStreamUserDetails(userDetails);
      await this.initializeVideo();
    } catch (error) {
      console.log(error, 'error in session init');
    }
  }

  async initializeVideo() {
    try {
      await this.agoraRTC.createTracks();
      this.agoraRTC.publisher.tracks.isAudioEnabled = true;
      this.agoraRTC.publisher.tracks.isVideoEnabled = true;
      await this.agoraRTC.joinChannel();
      await this.agoraRTC.publishTracks();
      this.registerSocketEvents();
    } catch (error) {
      console.log(error, 'error while initializing the video');
    }
  }

  async checkMeetingValidation() {
    const meetingObj = {
      meetingCode: this.userDetails.channelId,
      userType: this.userDetails.role,
      loginType: 'guest-login',
      uid: this.userDetails.userId
    }

    let mettingDetails = await this.meetingService.checkMeetingValidation(meetingObj);
    if (!mettingDetails.status) {
      this.toastr.warning(mettingDetails.error);
      this.closeSession();
      this.router.navigate(['/'], { relativeTo: this.route });
    } else {
      this.agoraRTC.setMeetingDetails(mettingDetails.data);
    }
  }

  registerSocketEvents() {
    const channelId = this.userDetails.channelId.toString();
    this.socketService.socketIO.emit('join-meeting', { channelId, msg: 'join meeting with channel id' });

    this.chatService
      .getMessages()
      .subscribe((data: any) => {
        if (!this.chatOpened && this.userDetails.userId != data.user.uid) {
          this.toastr.info(`${data.user?.displayName}: ${data.msg}`);
        }
        this.messages.push(data);
        this.newMessageCount++;
      });
  }
  // if you want to change setting in between the meeting.
  openSettingDialog() {
    const dialogRef = this.dialog.open(PreSessionScreenComponent);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  registerAgoraEvents() {
    this.agoraRTC.participantAdded.subscribe(user => {
      this.addRemoteUser(user);
    });

    this.agoraRTC.participantStatusUpdated.subscribe(user => {
      this.updateRemoteStream(user);
    });

    this.agoraRTC.participantRemoved.subscribe(user => {
      this.removeUserFromSession(user);
    });

    this.agoraRTC._agora.subscribe((event) => {
      if (event.type == 'screen-share-sttoped') {
        this.handleScreenShare();
      }

      if (event.type == 'volume-indicator') {
        this.mediaData.forEach((media: any, index: any) => {
          if (media.user?.uid == event.data.id) {
            media.setTalkingState(true);

            if (!this.mediaData[0].isScreen && !this.isGalleryView) {
              // just swap the video streams
              let stream = this.mediaData[index];
              this.mediaData[index] = this.mediaData[0];
              this.mediaData[0] = stream;
            }

          } else {
            media.setTalkingState(false);
          }
        });
      }

      // if (event.type == 'network-quality') {
      //   if (event.stats.downlinkNetworkQuality) {
      //     this.mediaData.forEach((media) => {
      //       if (media.streamId == this.userDetails.userId) {
      //         media.networkState = 6 - event.stats.downlinkNetworkQuality;
      //         this.networkState = 6 - event.stats.downlinkNetworkQuality;
      //       }
      //     });
      //   }
      // }
    });
  }

  async addRemoteUser(remote) {
    let userDetails: any = null;
    // for local user
    if (remote.uid == this.agoraRTC.streamUser.streamId) {
      userDetails = this.agoraRTC.streamUser;
    } else {
      let result: any = await this.meetingService.getParticipantByStreamId({ streamId: remote.uid, meetingId: this.userDetails.channelId });
      if (!result.status) {
        this.toastr.warning(result.error);
        return;
      }
      userDetails = result?.data;
    }

    const media = new Media(remote, userDetails);
    let remoteUserData = null;

    remoteUserData = this.mediaData.find((item) => item.user?.uid == userDetails.streamId);
    if (userDetails.isScreenStream) {
      if (this.isLocalScreen) {
        this.agoraRTC.stopScreenShare();
        this.isLocalScreen = false;
      }
      this.isScreenShared = true;
    }

    if (!remoteUserData) {
      if (userDetails.isScreenStream) {
        this.mediaData.unshift(media);
      } else {
        this.mediaData.push(media);
      }
    }
  }

  updateRemoteStream(remote) {
    this.mediaData.forEach(item => {
      if (item.user.uid == remote.uid) {
        item.setMedia(remote);
      }
    });
  }

  getUserDetails(userId): any {
    return new Promise((res) => {
      if (this.userData[userId]) {
        return res(this.userData[userId]);
      }
      this.fbService
        .getUserdata(this.userDetails.channelId, userId)
        .then((data: any) => {
          data.forEach((doc) => {
            let usrDetails = doc.data();
            this.userData[userId] = usrDetails;
            return res(usrDetails);
          });
        });
    });
  }

  // @description : get remote user details,
  // - check is screen stream then stop
  // remove user from media

  removeUserFromSession(user) {
    let media = this.mediaData.filter((item) => item.user.uid == user.uid);
    if (media[0] && media[0].userDetails.isScreenStream) {
      this.isScreenShared = false;
    }

    this.mediaData = this.mediaData.filter((item) => item.user.uid != user.uid);
  }

  handleNetworkState() {
    setTimeout(() => {
      this.handleNetwork = setInterval(() => {
        if (
          this.agoraRTC.publisher.client &&
          this.agoraRTC.publisher.isJoined
        ) {
          let networkStates = this.agoraRTC.publisher.client.getRemoteNetworkQuality();
          for (const [key, value] of Object.entries(networkStates)) {
            this.mediaData.forEach((media) => {
              if (media.userId == key && value.uplinkNetworkQuality) {
                media.networkState = 6 - value.uplinkNetworkQuality;
              }
            });
          }
        }
      }, 2000);
    }, 1000);
  }

  identify(index: any, media: any) {
    return (media && media.streamId) || index;
  }

  async handleScreenShare() {

    if (this.userDetails.role != 'host') {
      return;
    }
    if (!this.agoraRTC.publisher.isScreenShared) {
      try {
        if (this.isScreenShared) {
          this.isScreenShared = false;
          await this.agoraRTC.stopScreenShare();
        }
        let screenUserDetails = await this.meetingService.insertMeetingParticipants({ ...this.userDetails, isScreenStream: true, inTime: Date.now() });
        if (!screenUserDetails.status) {
          this.toastr.error('Something went wrong, failed to share screen!');
          return;
        }
        // while stop screen share ,update
        this.agoraRTC.setScreenUserDetails(screenUserDetails.data)
        await this.agoraRTC.shareScreen(this.userDetails);
        this.userDetails.isScreenStream = true;
        this.isLocalScreen = true;
        this.toastr.warning('You are sharing screen!');
      } catch (error) {
        this.isScreenShared = false;
        this.isLocalScreen = false;
        this.userDetails.isScreenStream = false;
        console.log(error);
      }
    } else {
      await this.agoraRTC.stopScreenShare();
      // this.isScreenShared = false;
      this.userDetails.isScreenStream = false;
      this.isLocalScreen = true;
      this.toastr.warning('Screen share stopped!');
    }
  }

  handleGridView() {
    this.isGalleryView = !this.isGalleryView;
  }

  // handleScreenLayout() {
  //   if (this.isScreenShared) {
  //     this.mediaData.forEach((media: any) => {
  //       if (!media.isLocal) {
  //         this.agoraRTC.setRemoteStreamType(media.streamId, 'low');
  //       }
  //       this.sideMediaData.push(media);
  //     });
  //     this.sideBarOpened = true;
  //     this.isGalleryView = false
  //     this.mediaData = [];
  //   } else {
  //     this.mediaData.push(this.sideMediaData.pop());
  //     this.handleSideBar();
  //   }
  // }

  checkElementExistent(id) {
    return new Promise((res, rej) => {
      let ele = document.getElementById(id);
      if (ele) {
        res(ele);
      } else {
        setInterval(() => {
          let ele = document.getElementById(id);
          if (ele) {
            res(ele);
          }
        }, 100);
      }
    });
  }

  muteStream(type) {

    if (this.userDetails.role != 'host') {
      return;
    }
    if (type == 'audio') {
      this.agoraRTC.publisher.tracks.isAudioEnabled = !this.agoraRTC.publisher
        .tracks.isAudioEnabled;
      this.agoraRTC.publisher.tracks.audio.setEnabled(
        this.agoraRTC.publisher.tracks.isAudioEnabled
      );
    }
    if (type == 'video') {
      this.agoraRTC.publisher.tracks.isVideoEnabled = !this.agoraRTC.publisher
        .tracks.isVideoEnabled;

      this.agoraRTC.publisher.tracks.video.setEnabled(
        this.agoraRTC.publisher.tracks.isVideoEnabled
      );
    }

    this.mediaData.forEach((media: any) => {
      if (media.user?.isLocal) {
        if (type == 'audio') {
          media.user.hasAudio = this.agoraRTC.publisher.tracks.isAudioEnabled;
        } else if (type == 'video') {
          media.user.hasVideo = this.agoraRTC.publisher.tracks.isVideoEnabled;
        }
      }
    });
  }

  async closeSession() {
    this.ngxLoader.start();
    await this.agoraRTC.closeSession();
    //TODO: @args : (userdetails ,participants -left,join )
    await this.meetingService.updateMeetingParticipants(this.userDetails);
    clearInterval(this.handleNetwork);
    this.handleNetwork = null;
    try {
      setTimeout(() => {
        if (this.agoraRTC.meetingDetails.meeting.redirectUrl) {
          window.location.replace(this.agoraRTC.meetingDetails.meeting.redirectUrl);
        } else {
          this.router.navigate(['/'], { relativeTo: this.route });
        }
        this.ngxLoader.stop();
      }, 1000);
    } catch (error) {
      console.log(error, 'got the exception here');
    }
  }

  handleRecording() {
    console.log('handleRecording---->>');
    if (this.recordingInProgress) {
      console.log('handleRecording---->>stop');
      clearInterval(this.recordingInterval);
      let resourceId = localStorage.getItem('resourceId');
      let sId = localStorage.getItem('sId');
      this.meetingService.stopRecording({ channelId: this.userDetails.channelId, resourceId, sId: sId }).subscribe((response: any) => {
        if (!response.status) {
          this.toastr.warning(response.data.message);
        }

        this.recordingInProgress = false;
        this.toastr.warning('recording stopped!');
        localStorage.removeItem('resourceId');
        localStorage.removeItem('sId');
      })

    } else {
      console.log('handleRecording---->>start');
      this.meetingService.startRecording({ channelId: this.userDetails.channelId }).subscribe((response: any) => {
        // console.log('this.meetingService.startRecording-->>>> nk12345 ', response);
        if (!response.status) {
         return this.toastr.warning(response.data.message);
        }

        this.recordingInProgress = true;
        this.toastr.warning('recording started');
        localStorage.setItem('resourceId', response.data.resourceId);
        localStorage.setItem('sId', response.data.sid);

        // recording status query

        this.recordingInterval = setInterval(() => {
          this.meetingService.queryRecording({ resourceId: response.data.resourceId, sId: response.data.sid, channelId: this.userDetails.channelId }).subscribe((response: any) => {
            // console.log('this.meetingService.queryRecording-->>>>', response);
            if (!response.status) {
              // this.toastr.warning(response.error);
              clearInterval(this.recordingInterval);
              this.recordingInProgress = false;
              this.toastr.warning('something went wrong, recording stopped!');
              localStorage.removeItem('resourceId');
              localStorage.removeItem('sId');
            }
          });
        }, 10000)

      })
    }
  }

  getUA() {
    let device = 'Unknown';
    const ua = {
      Linux: /Linux/i,
      Android: /Android/i,
      BlackBerry: /BlackBerry/i,
      Bluebird: /EF500/i,
      'Chrome OS': /CrOS/i,
      Datalogic: /DL-AXIS/i,
      Honeywell: /CT50/i,
      iPad: /iPad/i,
      iPhone: /iPhone/i,
      iPod: /iPod/i,
      macOS: /Macintosh/i,
      Windows: /IEMobile|Windows/i,
      Zebra: /TC70|TC55/i,
    };
    Object.keys(ua).map(
      (v) => navigator.userAgent.match(ua[v]) && (device = v)
    );
    return device;
  }

  toggleSideSection(type) {
    if (type == 'chat') {
      this.newMessageCount = 0;
      this.chatOpened = !this.chatOpened;
    }
  }

  setGridSpanValues(streamLength) {
    switch (true) {
      case streamLength == 2:
        this.gridColSpan = 8;
        this.gridRowSpan = 4;
        break;
      case streamLength > 2 && streamLength <= 4:
        this.gridColSpan = 8;
        this.gridRowSpan = 2;
        break;
      case streamLength > 4 && streamLength <= 8:
        this.gridColSpan = 4;
        this.gridRowSpan = 2;
        break;
      case streamLength > 8 && streamLength <= 12:
        this.gridColSpan = 4;
        this.gridRowSpan = 1;
        break;
      case streamLength > 12:
        this.gridColSpan = 4;
        this.gridRowSpan = 1;
        break;
      default:
        this.gridColSpan = 16;
        this.gridRowSpan = 4;
    }
  }

  // pinn user side section to grid or grid to side section
  // toggleUserTo(type, streamId) {
  //   if (!this.sideBarOpened) {
  //     return;
  //   }
  //   if (type == 'sideBar') {
  //     this.sideMediaData.push(this.mediaData.filter(media => media.streamId === streamId)[0]);
  //     this.mediaData = this.mediaData.filter(media => media.streamId !== streamId);
  //   } else {
  //     this.mediaData.push(this.sideMediaData.filter(media => media.streamId === streamId));
  //     this.sideMediaData = this.sideMediaData.filter(media => media.streamId !== streamId);
  //   }
  //   this.handleSideBar();
  //   this.setGridSpanValues(this.mediaData.length);
  // }

  // handleSideBar() {
  //   if (this.sideMediaData.length === 0) {
  //     this.sideBarOpened = false;
  //     this.isGalleryView = true;
  //   }
  // }

  ngOnDestroy(): void {
      if(this.recordingInProgress) {
        this.handleRecording();
      }
   }
}
