<ng-container>
  <!-- Chat container-->
  <div fxLayout="column" fxLayoutAlign="center center" class="chat-container">
    <!-- Messages-->
    <div fxLayout="column" class="message-container" id="message-container" #messageContainer>
      <ng-container *ngFor="let msg of messages;">
        <app-chat-message [msg]="msg"></app-chat-message>
      </ng-container>
    </div>

    <!-- Send form and other controls -->
    <app-chat-control [chatId]="chatId" style="padding:5px;"></app-chat-control>
  </div>
</ng-container>