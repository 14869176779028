
import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { Message } from 'src/app/shared/models/message';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit{
  @Input() height: string;
  @Input() width: string;
  @Input() chatId: string;
  @Input() messages: Array<any>;
  chat$: Observable<any>;
 
  constructor(
    public fbService: FirebaseService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    console.log('chat-nk msg',this.messages)
  }

  private isSameMessage(message: Message, newMessage: Message): boolean {
    return (
      message.content === newMessage.content &&
      message.uid === newMessage.uid &&
      message.createdAt.isSame(newMessage.createdAt)
    );
  }

  trackByCreated(msg) {
    return msg.createdAt;
  }
}
