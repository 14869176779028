import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, filter, throttleTime } from 'rxjs/operators';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { User } from 'src/app/shared/models/user';
import { StorageServiceService } from 'src/app/services/storage/storage-service.service';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { ChatService } from 'src/app/services/chat/chat.service';

@Component({
  selector: 'app-chat-control',
  templateUrl: './chat-control.component.html',
  styleUrls: ['./chat-control.component.scss'],
})
export class ChatControlsComponent implements OnInit {
  @Input() chatId: string;

  messageControl: FormControl;
  chatForm: FormGroup;
  userDetails: User;

  constructor(
    private fbService: FirebaseService,
    private fb: FormBuilder,
    public storage: StorageServiceService,
    private authService: AuthService,
    private chatService: ChatService
  ) {
    this.messageControl = new FormControl();
    this.chatForm = this.fb.group({ message: this.messageControl });

    let loginDetails = JSON.parse(
      this.storage.secureStorage.getItem('loginData')
    );
    // console.log('loginDetails---',loginDetails);
    // console.log('loginDetails---',authService.userData);
    // console.log('loginDetails---',authService.userIsLoggedIn);

    let userName = localStorage.getItem('username')
    console.log('userName---', userName);
      if(loginDetails && loginDetails.user){
        this.userDetails = {
          displayName: userName ? userName : loginDetails.user.displayName,
          photoUrl: loginDetails.user.photoUrl,
          uid: loginDetails.user.uid,
          email: loginDetails.user.email,
        };
      }
   
  }

  ngOnInit() {
    this.scrollBottom();
  }

  submit(): void {
    const msg = this.messageControl.value;
    if (!msg) {
      return alert('Please enter a message.');
    }

    let content = { msg: msg, user: this.userDetails,createdAt:new Date().toUTCString() };
    // this.fbService.sendMessage(this.chatId, content).then(resonse=>console.log('resonse from msg',resonse)).catch(error=> console.log('resonse from msg error:',error));
    this.chatService.sendMessage(this.chatId, content);
    this.messageControl.reset();
    this.scrollBottom();
  }

  private scrollBottom(): void {
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 500);
  }
}
