import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../shared/models/user'; // optional
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageServiceService } from '../storage/storage-service.service';
import { environment } from 'src/environments/environment';
import { SocialAuthService } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { ToastrService } from 'ngx-toastr';
@Injectable({ providedIn: 'root' })
export class AuthService {
  userIsLoggedIn: boolean = false;
  user$: Observable<User>;
  public userData: any;
  constructor(
    private router: Router,
    public storage: StorageServiceService,
    private http: HttpClient,
    private authService: SocialAuthService,
    private toastr: ToastrService
  ) {
    this.isLoggedIn();
  }



  // get google login url
  getGoogleLoginURL(): Observable<any> {
    try {
      return this.http.get(`${environment.apiURL}/auth/google-login-url`).pipe(map(res => res));
    } catch (error) {
      console.log('google sign in error [auth-service.ts]', error);
    }
  }

  async signInWithGoogle() {
    try {
      const googleUser = await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);

      const bodyObj = { idToken: googleUser.idToken };
      return this.http.post(`${environment.apiURL}/auth/google-login-verify-user`, bodyObj).toPromise();
    } catch (error) {
      console.log('signInWithGoogle error--', error);
    }
  }

  // Sets user data to localStorage on login
  public updateUserData(data) {
    this.userIsLoggedIn = true;
    const userData: any = {
      token: data.token,
      user: {
        uid: data.user._id,
        email: data.user.email || "",
        displayName: data.user.name,
        photoUrl: data.user.profilePhoto || ""
      }
    }

    this.userData = userData;
    this.storage.secureStorage.setItem('loginData', JSON.stringify(userData));
    // console.log("localstorage loginData====>", this.storage.secureStorage.getItem('loginData'));
  }

  public updateUser(bodyObj): any {
    return this.http.put(`${environment.apiURL}/users/update-username/${this.userData.user.uid}`, bodyObj).toPromise();
  }

  async loginAsGuest(username) {
    const result: any = await this.http.post(`${environment.apiURL}/auth/guest-login`, { username: username || "user" }).toPromise();
    if (!result.status) {
      this.toastr.error('failed to login.');
    } else {
      this.updateUserData(result.data);
    }
  }

  getUserData(userID): Observable<any> {
    try {
      return this.http.get(`${environment.apiURL}/users/get-user-by-id/${userID}`).pipe(map(res => res));
    } catch (error) {
      console.log('google sign in error [auth-service.ts]', error);
    }
  }

  async signOut() {
    this.storage.secureStorage.removeItem('loginData');
    this.userIsLoggedIn = false;
    // TODO: add logout api
    this.router.navigate(['/']);

  }

  isLoggedIn() {
    let loginData = this.storage.secureStorage.getItem('loginData');
    if (loginData) {
      loginData = JSON.parse(loginData);
      if (loginData && loginData.token) {
        this.userData = loginData;
        this.userIsLoggedIn = true;
        return true;
      }
    }

    this.userData = null;
    this.userIsLoggedIn = false;
    return false;
  }

  getAuthData() {
    return this.userData;
  }

  getToken() {
    if (this.isLoggedIn()) {
      return this.userData.token
    }
    return '';
  }
}
