import { Injectable } from '@angular/core';
import {io} from 'socket.io-client';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SocketIOService {
  public socketIO;
  constructor() { 
    this.socketIO = io(environment.baseURL);
  }

  
}
