<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="login-card">
        <mat-card-header>
            <mat-card-title>Log in</mat-card-title>
        </mat-card-header>

        <form class="example-form">
            <mat-card-content class="form-inputs">
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Username">
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Password">
                </mat-form-field>
            </mat-card-content>
            <div class="form-btns">
                <button mat-stroked-button color="accent" class="btn-block" style="margin-right:10px;">Log in</button>
                <img src="assets/btn_google_signin_dark_focus_web@2x.png" class="google-login-button" width="150px"
                    (click)="signInWithGoogle()" alt="google icon">
            </div>
        </form>
    </mat-card>
</div>