import {IAgoraRTCRemoteUser} from 'agora-rtc-sdk-ng';
import {EventEmitter} from '@angular/core';
// import { User } from 'src/app/shared/models/user';
export class Media {

  id: any = null;
  user: IAgoraRTCRemoteUser | undefined;
  mediaStatusChanged: EventEmitter<boolean>;
  isScreen: boolean = false;
  isTalking$ = new EventEmitter<boolean>();
  public isTalking: boolean;
  userDetails: any;

  constructor(user: IAgoraRTCRemoteUser | undefined = undefined,userDetails: any) {
    this.mediaStatusChanged = new EventEmitter<boolean>();
    this.user = user;
    this.isTalking = false;
    this.userDetails = userDetails;
    if(userDetails && user) {
      this.isScreen = userDetails.isScreenStream;
      this.id = user.uid;
    }
  }

  public setTalkingState(state) {
    if (this.isTalking !== state) {
      this.isTalking = state;
      this.isTalking$.next(state);
    }
  }

  public setMedia(user: IAgoraRTCRemoteUser) {
    this.user = user;
    this.mediaStatusChanged.emit(true);
  }
}
