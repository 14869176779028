<div style="display: flex; flex-direction: column;">
    <mat-card
      style="box-shadow: none; background-color: transparent;"
      [ngStyle]="{
        'align-self': 'flex-start',
        'padding-top': '0px',
        'padding-left': '0px',
        'padding-bottom': '0px',
        width:'100%'
      }"
      class="chat-msg-card"
    >
      <!-- Message Header -->
      <mat-card-header
        [ngStyle]="{
          'justify-content': 'flex-start',
          'margin-bottom': '2px',
          'flex-direction': 'row',
          'align-items': 'center'
        }"
      >
        <img
          mat-card-avatar
          [src]="msg.user?.photoUrl || 'assets/images/account.jpeg'"
          class="profile-icon"
        />
        <mat-card-title
          style="font-size: 12px; margin-bottom: 0;"
          class="content-text"
        >
          {{ getUserName(msg.user) }}
        </mat-card-title>
      </mat-card-header>
      <mat-card-content
        style="display: inline-flex; flex-direction: column; width: 100%;"
      >
        <!-- Message content-->
        <div
          [ngStyle]="{
            'align-self': 'flex-start',
            'text-align' : 'start',
            'min-width': '150px',
            'max-width': '100%',
            'margin-bottom': '8px',
            'padding': '3px 12px'
          }"
          class="content-text"
        >
          <div style="top: 2px;position: absolute;right: 4px; font-size: 12px; opacity: 0.6;font-size: 80%;margin-right: 10px;" class="content-text">
            {{ getCreatedDate(msg) }}
          </div>
          <span style="overflow-wrap: break-word;font-size: 90%;">{{ msg.msg }}</span>
        </div>
  
        <button
          *ngIf="allowsReply"
          [ngStyle]="{
            'align-self': 'flex-start',
            'margin-bottom': '8px',
            'font-size': '10px',
            'line-height': '18px'
          }"
          mat-stroked-button
        >
          REPLY
        </button>
      </mat-card-content>
    </mat-card>
  </div>
  