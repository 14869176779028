import { environment } from "src/environments/environment";

const getMeetingURL = `${environment.apiURL}/meeting/get-meeting-by-id`;
const createNewMeetingURL = `${environment.apiURL}/meeting/create-new-meeting`;
const getMeetingDetails = `${environment.apiURL}/meeting/get-meeting-details`;
const updateMeetingParticipants = `${environment.apiURL}/meeting/insert-update-participants`;
const getMeetingParticipantByUserId = `${environment.apiURL}/meeting/participant`;
const getMeetingParticipantByStreamId = `${environment.apiURL}/meeting/participant-by-stream-id`;
const insertMeetingParticipants = `${environment.apiURL}/meeting/insert-participant`;
const updateMeetingParticipant = `${environment.apiURL}/meeting/update-participant`;
const agoraRecordingStart = `${environment.apiURL}/recording/agora-start`;
const agoraRecordingStop = `${environment.apiURL}/recording/agora-stop`;
const agoraRecordingQuery = `${environment.apiURL}/recording/agora-query`;

export default {
    getMeetingURL,
    createNewMeetingURL,
    getMeetingDetails,
    updateMeetingParticipants,
    getMeetingParticipantByUserId,
    getMeetingParticipantByStreamId,
    insertMeetingParticipants,
    updateMeetingParticipant,
    agoraRecordingStart,
    agoraRecordingStop,
    agoraRecordingQuery
}
