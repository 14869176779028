import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreSessionScreenComponent } from './pages/agora/pre-session-screen/pre-session-screen.component';
import { AgoraComponent } from './pages/agora/agora.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { AuthGuard } from './services/guard/auth.guard';

const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'meeting/:meetingId',
    component: AgoraComponent,
  },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
