<div class="grid-content-cantainer" [ngClass]="{'talking-tile' : isTalking}">
    <div class="grid-tile-top-controls">
      <span
        class="
          material-icons
          grid-tile-top-controls-icon
          grid-tile-top-controls-mic-icon
        "
        *ngIf="true"
        [style.font-size]="'16px'"
        [style.margin]="'5px'"
      >
        {{ media.user?.hasAudio ? "mic" : "mic_off" }}
      </span>
    </div>
    <div class="{{ media.user?.hasVideo ? 'user-stream active' : 'user-stream deactive' }}" #streamContainer id="{{elementId}}"></div>
    <div class="default-profile-icon" class="{{ media.user?.hasVideo ? 'default-profile-icon deactive' : 'default-profile-icon active' }}" >
    <span class="material-icons mat-grid-user-icon" [style.font-size]="'5rem'">
      account_circle
    </span>
    </div>
    <div class="grid-tile-bottom-controls users-details" [ngClass]="'min-card'">
      <div class="user-name"><span class="user-name-item">{{ userName }}</span></div>
    </div>
  </div>

  
