<div class="preparatory-screen" *ngIf="!agoraRTC.setupDone">
  <app-pre-session-screen></app-pre-session-screen>
</div>
<div class="session-container custom-background" *ngIf="agoraRTC.setupDone" ngxUiLoaderBlurred>
    <div class="second-container custom-background">
      <mat-drawer-container class="session-drawer-container custom-background"
        autosize>
        <mat-drawer-content class="custom-background">
          <div class="custom-background streams-container">
            <div class="grid grid--{{mediaData?.length > MAX_VIDEO_TILES ? MAX_VIDEO_TILES : mediaData?.length}}"
              [ngClass]="{screenSharing: isScreenShared, speakerView: !isGalleryView}">
              <ng-container *ngFor="let item of mediaData; let i = index;">
                <div class="video-{{i + 1}} videoContainer" id="{{i}}">
                  <app-stream-view [media]="item"></app-stream-view>
                </div>
              </ng-container>
            </div>
          </div>
        </mat-drawer-content>
        <mat-drawer #drawer [(opened)]="chatOpened" class="session-right-drawer"
          style="background-color: rgb(69 69 69); border-radius: 5px;" mode="side" position="end">
          <app-chat [chatId]="userDetails.channelId" [messages]="messages"></app-chat>
        </mat-drawer>
      </mat-drawer-container>
    </div>
    <meeting-controls (muteStream)="muteStream($event)" (handleScreenShare)="handleScreenShare()"
      (handleGridView)="handleGridView()" (toggleSideSection)="toggleSideSection($event)" (closeSession)="closeSession()"
      (handleRecording)="handleRecording()" [isRecording]="recordingInProgress"
    >
    </meeting-controls>
</div>
<ngx-ui-loader></ngx-ui-loader>
