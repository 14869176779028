import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import meetingConstant from './meetingConstant';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MeetingService {

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
  ) { }

  getMeetingById(meetingId) {
    return this.http.get(`${meetingConstant.getMeetingURL}/${meetingId}`);
  }
  createNewMeeting() {
    const bodyData = {
      organizationID: "org 3",
      hostUserID: "or3@m.co",
      startTime: Date.now(),
      duration: 720,
      redirectUrl: ''
    }
    return this.http.post(`${meetingConstant.createNewMeetingURL}`, { body: bodyData });
  }
  getMettingDetails(meetingId): any {
    return this.http.get(`${meetingConstant.getMeetingURL}/${meetingId}`).toPromise();
  }

  //1.meeting code is valid or not
  //2.is meeting time is valid
  //3.is meeting expired
  async isMeetingValid(meetingCode) {
    let mettingResult = await this.getMettingDetails(meetingCode);

    if (!mettingResult.status) {
      return {
        status: false,
        error: 'Invalid meeting credentials'
      }
    }
    else if (moment(new Date()).isBefore(mettingResult.data.startTime)) {
      return {
        status: false,
        error: 'Meeting not will start soon'
      }
    }
    else if (moment(mettingResult.data.startTime).add(mettingResult.data.duration, 'minutes').isBefore(new Date())) {
      return {
        status: false,
        error: 'Meeting ended'
      }
    }

    return mettingResult;
  }

  async checkMeetingValidation(options) {
    // let mettingDetails = await this.isMeetingValid(meetingCode);
    const mettingDetails: any = await this.http.get(`${meetingConstant.getMeetingDetails}?meetingId=${options.meetingCode}&userId=${options.uid}&loginType=${options.loginType}&userType=${options.userType}`).toPromise();

    if (!mettingDetails.status) {
      this.toastr.warning(mettingDetails.error);
    }
    return mettingDetails;
  }

  // async updateMeetingParticipants(userDetails, updateType) {
  //   const body = {
  //     userId: userDetails.userId,
  //     inTime: Date.now(),
  //     outTime: Date.now(),
  //     updateType: updateType,
  //     role: userDetails.role == 'host' ? 1 : 0,
  //     loginType: 'guest-login',
  //     username: localStorage.getItem('username') || 'user',
  //     isScreenStream:userDetails.isScreenStream
  //   }
  //   const result: any = await this.http.post(`${meetingConstant.updateMeetingParticipants}/${userDetails.channelId}`, body ).toPromise();
  //   console.log('updateMeetingParticipants nk5566 ---', result);

  //   if (!result.status) {
  //     this.toastr.warning(result.error);
  //   }
  //   return result;
  // }

  async getParticipantByUserId(options) {
    return this.http.get(`${meetingConstant.getMeetingParticipantByUserId}/${options.meetingId}?userId=${options.userId}`).toPromise();
  }

  async getParticipantByStreamId(options) {
    return this.http.get(`${meetingConstant.getMeetingParticipantByStreamId}/${options.meetingId}?streamid=${options.streamId}`).toPromise();
  }
  async insertMeetingParticipants(userDetails) {
    const body = {
      channelId: userDetails.channelId,
      userId: userDetails.userId,
      inTime: Date.now(),
      role: userDetails.role == 'host' ? 1 : 0,
      loginType: 'guest-login',
      username: localStorage.getItem('username') || 'user',
      isScreenStream: userDetails.isScreenStream
    }
    const result: any = await this.http.post(`${meetingConstant.insertMeetingParticipants}`, body).toPromise();

    if (!result.status) {
      this.toastr.warning(result.error);
    }
    return result;
  }

  async updateMeetingParticipants(userDetails) {
    const body = {
      userId: userDetails.userId,
      outTime: Date.now(),
    }
    const result: any = await this.http.post(`${meetingConstant.updateMeetingParticipant}/${userDetails.channelId}`, body).toPromise();

    if (!result.status) {
      this.toastr.warning(result.error);
    }
    return result;
  }

   startRecording(userDetails): Observable <any> {
    const body = {
      channelId: userDetails.channelId
    }
    return this.http.post(`${meetingConstant.agoraRecordingStart}`, body);
  }

   stopRecording(userDetails): Observable <any> {
    const body = {
      channelId: userDetails.channelId,
      sId: userDetails.sId,
      resourceId: userDetails.resourceId
    }
    return  this.http.post(`${meetingConstant.agoraRecordingStop}`, body);
  }

  queryRecording(userDetails): Observable <any> {
    const body = {
      resourceId: userDetails.resourceId,
      sId: userDetails.sId
    }
    return this.http.post(`${meetingConstant.agoraRecordingQuery}`, body);
  }

}
