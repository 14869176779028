import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  AfterViewInit,
} from '@angular/core';
import * as moment from 'moment';
import { Message } from 'src/app/shared/models/message';
import { User } from 'src/app/shared/models/user';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent
  implements OnInit, AfterViewInit{
  @Input() msg: any;
  @Input() predecessor: any;
  @Input() user: User;
  @Input() allowsReply = false;

  @ViewChild('messageContainer') private msgContainer: ElementRef;
  constructor() {
   
  }

  ngOnInit() {
   
  }

  ngAfterViewInit() {
    let containerEle = document.getElementById('message-container');
    containerEle.scrollTop = containerEle.scrollHeight;
  }
  
  getUserName(user: User): string {
    if (!user) {
      return null;
    }
    return user.displayName;
  }

  getCreatedDate(msg: Message): string {
    if (!msg.createdAt) {
      return null;
    }
    // let timestamp: any = msg.createdAt.toDate(); 
    // console.log('msg timestamp --',msg.createdAt ,timestamp);
    // .toDateString()
    let timestamp = moment(this.msg.createdAt).format("DD/MM/YYYY HH:mm:ss");
    return timestamp;
  }
}
