import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import AgoraRTC, {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';
import { ToastrService } from 'ngx-toastr';
import { AgoraRtcService } from 'src/app/services/agora/agora-rtc.service';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { ElementStatus } from 'src/app/shared/models/elementStatus';
import { StorageServiceService } from 'src/app/services/storage/storage-service.service';
import { MeetingService } from 'src/app/services/meeting/meeting.service';
import { AuthService } from 'src/app/services/auth/auth-service.service';

@Component({
  selector: 'app-pre-session-screen',
  templateUrl: './pre-session-screen.component.html',
  styleUrls: ['./pre-session-screen.component.scss'],
})
export class PreSessionScreenComponent implements OnInit {
  audioDevices: MediaDeviceInfo[];
  videoDevices: MediaDeviceInfo[];

  public selectedAudioTrack: IMicrophoneAudioTrack;
  public selectedVideoTrack: ICameraVideoTrack;

  private interval;

  public videoStatus: ElementStatus = {};
  public audioStatus: ElementStatus = {};
  public permissionStatus: ElementStatus = {};
  public volumeData: { avg: number; val: number };
  userId = '';
  classId = '';
  classTypeId = '';
  artistId = '';
  userName = '';
  isArtist = false;
  meetingCode = null;
  constructor(
    // public dialogRef: MatDialogRef<PreSessionScreenComponent>,
    // @Inject(MAT_DIALOG_DATA) public passedData,
    public agoraRTC: AgoraRtcService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    public storage: StorageServiceService,
    private meetingService: MeetingService,
    private authService: AuthService,

  ) { }

  private micVolume() {
    const level =
      this.selectedAudioTrack &&
      !this.audioStatus.loading &&
      !this.audioStatus.error &&
      this.selectedAudioTrack.getVolumeLevel();
    if (level >= 0) {
      if (this.volumeData.avg === 0 || this.volumeData.avg <= level) {
        this.volumeData.avg = level;
      } else {
        this.volumeData.avg = 0.7 * this.volumeData.avg + 0.3 * level;
      }
      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      let logLevel = Math.log(this.volumeData.avg) / Math.LN10 / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);
      this.volumeData.val = logLevel * 100;
    } else {
      this.initVolumeData();
    }
  }

  ngOnInit(): void {
    this.initVolumeData();
    AgoraRTC.getDevices()
      .then((devices) => {
        this.permissionStatus.loading = false;
        this.permissionStatus.error = false;
        this.audioDevices = devices.filter((x) => x.kind === 'audioinput');
        this.videoDevices = devices.filter((x) => x.kind === 'videoinput');

        const audioId =
          this.agoraRTC.publisher.tracks.audioId ||
          this.getDefaultDevice(this.audioDevices);
        const videoId =
          this.agoraRTC.publisher.tracks.videoId ||
          this.getDefaultDevice(this.videoDevices);

        if (audioId) {
          this.setupAudioTrack(audioId);
        } else {
          this.audioStatus.loading = false;
          this.audioStatus.error = true;
        }

        if (videoId) {
          this.setupVideoTrack(videoId);
        } else {
          this.videoStatus.loading = false;
          this.videoStatus.error = true;
        }

        this.interval = setInterval(() => {
          this.micVolume();
        }, 50);
      })
      .catch((error) => {
        this.permissionStatus.loading = false;
        this.permissionStatus.error = true;
        if (error.code === 'PERMISSION_DENIED') {
          this.permissionStatus.errorMessage = error.code;
        }
        console.log(error);
      });
    this.isInitRedirectUrl();
  }
  // store sesion url for redirection, in agora session component
  private isInitRedirectUrl = async () => {
    sessionStorage.setItem('isInitRedirectUrl', location.pathname);
  }
  private getDefaultDevice(devices: MediaDeviceInfo[]) {
    const defaultDevice = devices.find((x) => x.deviceId === 'default');
    if (defaultDevice) {
      return defaultDevice.deviceId;
    }
    if (devices.length > 0) {
      return devices[0].deviceId;
    }

    return null;
  }

  private setupAudioTrack(deviceId) {
    this.audioStatus.loading = true;
    AgoraRTC.createMicrophoneAudioTrack({
      microphoneId: deviceId,
      AEC: true, // acoustic echo cancellation
      AGC: true, // audio gain control
      ANS: true, // automatic noise suppression
      encoderConfig: 'speech_standard',
    }).then((track) => {
      this.selectedAudioTrack = track;
      this.initVolumeData();
      this.agoraRTC.publisher.tracks.audioVolume = 100;
      this.agoraRTC.publisher.tracks.audioId = deviceId;
      this.agoraRTC.publisher.tracks.audio = track;
      this.audioStatus.loading = false;
    });
  }

  private setupVideoTrack(deviceId) {
    AgoraRTC.createCameraVideoTrack({
      cameraId: deviceId,
      encoderConfig: '720p',
      optimizationMode: 'detail',
    }).then((track) => {
      this.selectedVideoTrack = track;
      this.selectedVideoTrack.play('videoPreview');
      this.agoraRTC.publisher.tracks.videoId = deviceId;
      this.agoraRTC.publisher.tracks.video = track;
      this.videoStatus.loading = false;
    });
  }

  changeStreamSource(deviceIndex, deviceType) {
    switch (deviceType) {
      case 'video':
        this.setupVideoTrack(deviceIndex);
        break;
      case 'audio':
        this.setupAudioTrack(deviceIndex);
        break;
    }
  }

  async joinSession() {
    // this.userName
    if (AgoraRTC.checkSystemRequirements()) {
      try {
        // login as guest
        // if (!this.authService.isLoggedIn()) {
        // }
        await this.authService.loginAsGuest(this.userName);

        localStorage.setItem('username', this.userName);
        this.agoraRTC.setupComplete();
      } catch (error) {
        console.log(error, 'error');
      }
    } else {
      this.toastr.warning('Please give permission to system devices!');
      // this.dialogRef.close();
    }
  }

  private initVolumeData() {
    this.volumeData = { avg: 0, val: 0 };
  }

  cancelJoin() {
    this.router.navigate(['/'], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    // this.agoraRTC.closeSession();
  }
}
