import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'blueMeet';
  public isLandingPage = false;
  public subscription: Subscription;
  public summercamp = false;
  public meetingRoom = false;
  public settingRoom = false;
  constructor(private router: Router) {
    this.subscription = router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/landingPage') {
          this.isLandingPage = true;
        } else {
          this.isLandingPage = false;
        }
        if (e.url.includes('/summerCamp')) {
          this.summercamp = true;
        } else {
          this.summercamp = false;
        }
        if (e.url.includes('/meeting')) {
          this.meetingRoom = true;
        } else {
          this.meetingRoom = false;
        }
        if (e.url.includes('/preSession')) {
          this.settingRoom = true;
        } else {
          this.settingRoom = false;
        }
      }
    });
  }
}
