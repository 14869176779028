import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import firebase from 'firebase';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  signInAttempt = 0;
  signUpAttempt = 0;
  isSignedIn = false;
  constructor(public db: AngularFirestore, private afAuth: AngularFireAuth) {}

  createMeeting(value) {
    return this.db.collection('meetings').add({
     createdAt: firebase.firestore.Timestamp.now(),
      isEnd:false
    });
  }

  getSessionMessages(chatId: string) {
    let promise = new Promise((resolve, reject) => {
      this.db
        .collection('chats')
        .doc(chatId)
        .collection('messages',ref =>
        ref.orderBy('createdAt','asc'))
        .get()
        .toPromise()
        .then(
          (res) => {
            // Success
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  getMessages(chatId: string) {
    return this.db
      .collection('chats')
      .doc(chatId)
      .collection('messages')
      .stateChanges(['added'])
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  // getUser(userKey, chatId) {
  //   return this.db.collection('users').doc(userKey).snapshotChanges();
  // }
  getUser(userKey) {
    return this.db.collection('users').doc(userKey).get().toPromise();
  }
  getMettingDetails(mettingId) {
    return this.db.collection('meetings').doc(mettingId).get().toPromise();
  }
  getSessionUser(userId, chatId) {
    return this.db
      .collection('users')
      .doc(chatId)
      .collection('session-users', (ref) => ref.where('userId', '==', userId))
      .get();
  }

  // updateUser(userKey, value) {
  //   value.nameToSearch = value.name.toLowerCase();
  //   return this.db.collection('users').doc(userKey).set(value);
  // }

  updateUser(userKey, value) {
    return this.db.collection('users').doc(userKey).update(value);
  }
  deleteUser(userId, streamId, chatId) {
    // streamId which we dont want to delete
    return new Promise((res, rej) => {
      this.getSessionUser(userId, chatId).subscribe((doc) => {
        doc.forEach((element) => {
          if (element.id != String(streamId)) {
            element.ref.delete();
            console.log(`deleted: ${element.id}`);
          }
        });
        res(true);
      });
    });
  }

  getUsers() {
    return this.db.collection('users').snapshotChanges();
  }

  searchUsers(searchValue) {
    return this.db
      .collection('users', (ref) =>
        ref
          .where('nameToSearch', '>=', searchValue)
          .where('nameToSearch', '<=', searchValue + '\uf8ff')
      )
      .snapshotChanges();
  }

  createUser(value, avatar) {
    return this.db.collection('users').add({
      name: value.name,
      nameToSearch: value.name.toLowerCase(),
      surname: value.surname,
      age: parseInt(value.age),
      avatar: avatar,
    });
  }

  getSession(sessionId) {
    return this.db.collection('sessions').doc(sessionId).snapshotChanges();
  }

  async sendMessage(chatId: string, content: any): Promise<any> {
    const data = {
      ...content,
      createdAt: firebase.firestore.Timestamp.now(),
    };
    return this.db
      .collection('chats')
      .doc(chatId)
      .collection('messages')
      .add(data);
  }

  saveUserDetails(chatId, userDetails) {
    try {
      return this.db
        .collection('users')
        .doc(chatId)
        .collection('session-users')
        .doc(String(userDetails.streamId))
        .set({ ...userDetails }, { merge: true });
    } catch (error) {
      console.log(error);
    }
  }

  registerSessionUpdate(sessionId) {
    // triggered when user get deleted
    return this.db
      .collection('users')
      .doc(sessionId)
      .collection('session-users')
      .stateChanges(['removed'])
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getUserdata(chatId, streamId) {
    let promise = new Promise((resolve, reject) => {
      this.db
        .collection('users')
        .doc(chatId)
        .collection('session-users', (ref) =>
          ref.where(
            firebase.firestore.FieldPath.documentId(),
            '==',
            String(streamId)
          )
        )
        .get()
        .toPromise()
        .then(
          (res) => {
            // Success
            console.log(res, 'this is the data resolved');
            resolve(res);
          },
          (msg) => {
            // Error
            reject(msg);
          }
        );
    });
    return promise;
  }
  loginIntoFirbase(email) {
    this.signInToFirebase(email);
  }

  private async signInToFirebase(email): Promise<void> {
    if (this.isSignedIn) {
      return;
    }

    this.isSignedIn = true;
    const password = 'MC6ZX287YvuvPu7dFEmrjKp6uLrbNEc7';

    try {
      await this.signInWithRetries(email, password);
    } catch (signInError) {
      await this.signUpWithRetries(email, password);
    }
  }

  private async signInWithRetries(
    email: string,
    password: string
  ): Promise<boolean> {
    this.signInAttempt++;

    try {
      const userCredential = await this.afAuth.signInWithEmailAndPassword(
        email,
        password
      );
      return true;
    } catch (error) {
      if (error && error.code === 'auth/user-not-found') {
        throw error;
      } else if (error && error.code === 'auth/wrong-password') {
        return false;
      } else {
        if (this.signInAttempt === 3) {
          console.log(error, 'login error in firebase');
          return false;
        }

        return await this.signInWithRetries(email, password);
      }
    }
  }

  private async signUpWithRetries(
    email: string,
    password: string
  ): Promise<boolean> {
    this.signUpAttempt++;

    try {
      const userCredential = await this.afAuth.createUserWithEmailAndPassword(
        email,
        password
      );
      return true;
    } catch (error) {
      if (error && error.code === 'auth/email-already-in-use') {
        try {
          return await this.signInWithRetries(email, password);
        } catch (error) {
          console.log(error, 'error in firestore signup');
          return false;
        }
      } else {
        if (this.signInAttempt === 3) {
          return false;
        }
        return await this.signUpWithRetries(email, password);
      }
    }
  }
}
