<div class="control-container">
  <mat-toolbar class="control-section">
    <!-- <div class="logo"><a [routerLink]="['/home']" routerLinkActive="active"><img src="assets/images/logo.png"
            alt=""></a>
        </div> -->
    <div *ngIf="isRecording">
      <svg height="50px" width="50px" class="blinking">
        <circle cx="50%" cy="50%" r="10" fill="red" />
        Sorry, your browser does not support inline SVG.
      </svg>
    </div>
    <div id="buttons-container" class="buttons-container">
      <div class="text-center control-button" *ngIf="agoraRTC.showRecordingButton">
        <button matTooltip="{{isRecording?'stop recording':'start recording'}}" mat-mini-fab id="mic-btn" type="button" class="btn btn-danger"
          (click)="_handleRecording()" color="success">
          <span class="material-icons recording-icon">
            {{isRecording ? "stop":"fiber_manual_record"}}
          </span>
        </button>
      </div>
      <div class="text-center control-button">
        <button matTooltip="{{
                agoraRTC.publisher.tracks.isAudioEnabled
                  ? 'mute audio'
                  : 'unmute audio'
              }}" mat-mini-fab id="mic-btn" type="button" class="microphonebtn btn btn-danger"
          (click)="_muteStream('audio')" color="success">
          <span class="material-icons">
            {{ agoraRTC.publisher.tracks.isAudioEnabled ? "mic" : "mic_off" }}
          </span>
        </button>
      </div>
      <div class="text-center control-button">
        <button color="success" mat-mini-fab matTooltip="{{
                agoraRTC.publisher.tracks.isVideoEnabled
                  ? 'disable video'
                  : 'enable video'
              }}" id="video-btn" type="button" class="disconnectbtn btn btn-danger" (click)="_muteStream('video')">
          <span class="material-icons">
            {{
            agoraRTC.publisher.tracks.isVideoEnabled
            ? "videocam"
            : "videocam_off"
            }}
          </span>
        </button>
      </div>
      <div id="screen-share-btn-container" class="text-center screensharecircle control-button">
        <button color="success" mat-mini-fab matTooltip="{{
                agoraRTC.publisher.isScreenShared
                  ? isLocalScreen
                    ? 'stop screen share'
                    : 'only one user can share screen at a time'
                  : 'share screen'
              }}" id="screen-share-btn" type="button" class="btn btn-lg btn-danger" (click)="_handleScreenShare()">
          <!-- [disabled]="(agoraRTC.publisher.isScreenShared || isScreenShared ) && !isLocalScreen" -->
          <span class="material-icons">
            {{
            agoraRTC.publisher.isScreenShared
            ? "stop_screen_share"
            : "screen_share"
            }}
          </span>
        </button>
      </div>

      <div class="text-center control-button">
        <button color="success" mat-mini-fab matTooltip="{{ isGalleryView ? 'floor view' : 'gallery view' }}"
          id="grid-view-button" type="button" class="btn btn-block btn-danger btn-lg videosccreenbtn"
          (click)="_handleGridView()">
          <span class="material-icons">
            {{ isGalleryView ? "grid_off" : "grid_on" }}
          </span>
        </button>
      </div>
      <div class="text-center control-button">
        <button color="success" mat-mini-fab matTooltip="chat" id="chat-button" type="button"
          class="btn btn-block btn-danger btn-lg videosccreenbtn" (click)="_toggleSideSection('chat')" mat-raised-button
          [matBadge]="newMessageCount" [matBadgeHidden]="chatOpened || newMessageCount == 0" matBadgePosition="after"
          matBadgeColor="primary" matBadgeSize="small">
          <span class="material-icons"> chat </span>
        </button>
      </div>
      <div class="text-center control-button">
        <button color="success" mat-mini-fab matTooltip="end call" id="exit-btn" type="button"
          class="btn btn-block btn-danger btn-lg videosccreenbtn" (click)="_closeSession()">
          <span class="material-icons" style="color: red;">
            call_end
          </span>
        </button>
      </div>
    </div>
  </mat-toolbar>
</div>
