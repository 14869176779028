import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AgoraRtcService } from 'src/app/services/agora/agora-rtc.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-create-meeting-dialog',
  templateUrl: './create-meeting-dialog.component.html',
  styleUrls: ['./create-meeting-dialog.component.scss']
})
export class CreateMeetingDialogComponent implements OnInit {
  attendeeMeetingUrl: string;
  hostMeetingUrl: string;
  fullAttendeeMeetingUrl: string;
  fullHostMeetingUrl: string;
  baseUrl: String;

  constructor(
    public dialogRef: MatDialogRef<CreateMeetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private rtc: AgoraRtcService
  ) {
    const meetingData = this.rtc.meetingDetails
    console.log('meetingData--', meetingData);
    this.baseUrl = environment.meetingBaseUrl;
    this.hostMeetingUrl = `/meeting/${meetingData.meetingDetails.host}`;
    this.attendeeMeetingUrl = `/meeting/${meetingData.meetingDetails.attendee}`;

    this.fullAttendeeMeetingUrl = this.baseUrl + this.attendeeMeetingUrl;
    this.fullHostMeetingUrl = this.baseUrl + this.hostMeetingUrl;
  }
  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }



}
