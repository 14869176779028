export const environment = {
  agoraConfig: {
    appId: 'de995c484de04d6e8681ad7b29296847',
    customerCertificate: '8833ae4d2d2144d6a3dc2a3b105d4072',
    customerId: 'edb63e4b71d1443ab986a8bfca5f3caa',
  },
  AGORA_APP_ID: 'de995c484de04d6e8681ad7b29296847',
  storageConfig: {
    vendor: 1,
    region: 15,
    bucket: 'agora-mcd-recording',
    accessKey: 'AKIA4DJFW6UXLWKT4HC3',
    secretKey: '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',
    fileNamePrefix: ['recordings'],
  },
  firebase: {
    apiKey: "AIzaSyDbIXXgc-si2DyywonYI6BWIErfaHehvy8",
    authDomain: "live-stream-2e8ee.firebaseapp.com",
    projectId: "live-stream-2e8ee",
    storageBucket: "live-stream-2e8ee.appspot.com",
    messagingSenderId: "509337659554",
    appId: "1:509337659554:web:d09e874a0c5c0b22268ffb",
    measurementId: "G-L400EGCP1L"
  },

  // meetingBaseUrl : 'http://localhost:4200',
  // // meetingBaseUrl : 'https://bluemeet.netlify.app',
  // apiURL:'http://localhost:9000/api/v1',
  // baseURL:'http://localhost:9000',


  // meetingBaseUrl : 'https://bluemeet1.netlify.app',
  // apiURL:'https://bluemeet1.herokuapp.com/api/v1',
  // baseURL:'https://bluemeet1.herokuapp.com'

  meetingBaseUrl: 'https://service-mycondolences.herokuapp.com/',
  apiURL: 'https://service-mycondolences.herokuapp.com/api/v1',
  baseURL: 'https://service-mycondolences.herokuapp.com/'

};

