<ng-container>
    <div class="home-container">
        <div class="left-container">
            <img src="assets/images/online-meeting-6719379.png" alt="meeting">
        </div>
        <div class="right-container" *ngIf="0">
            <div class="right-contents">
                <div class="btn-container" *ngIf="!auth.userIsLoggedIn">
                    <h3 class="sigin-text">Sign in to Create or Join metting</h3>
                    <img src="assets/btn_google_signin_dark_focus_web@2x.png" class="google-signin-img" alt="meeting"
                        (click)="signInWithGoogle()">
                </div>
                <div class="btn-container" *ngIf="auth.userIsLoggedIn">

                    <button mat-flat-button color="primary" class="create-mee-btn" disabled="{{loading}}"
                        (click)="createMetting()">Create meeting</button>
                    <p class="create-text">Click to start metting instantly</p>
                    <mat-divider></mat-divider>

                    <form class="example-form">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>Enter code</mat-label>
                            <input matInput [(ngModel)]="meetingCodeInput" name="meetingCodeInput" maxlength="200"
                                placeholder="xprlodjt">
                            <mat-hint align="start"><strong>Enter metting code to join</strong> </mat-hint>

                        </mat-form-field>
                    </form>
                    <button mat-flat-button color="accent" class="join-mee-btn" (click)="joinMetting()">Join
                        now</button>
                </div>
            </div>
        </div>
    </div>
</ng-container>