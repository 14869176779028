import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Publisher } from '../../shared/models/publisher'
@Injectable({
  providedIn: 'root',
})
export class AgoraRtcService {
  public credentials: {};
  public setupDone: boolean;
  public meetingPreparation: boolean = false;
  private THRESHOLD_VOLUME = 10;

  public publisher: Publisher;
  public screenId: any;
  // public remotes: RemoteUser[];
  public audioDevices: Array<any>;
  public videoDevices: Array<any>;

  public meetingDetails: any;
  public userCredentials: any;

  public screenUser: any;
  public streamUser: any;

  public isChannelJoining = false;
  public _agora = new EventEmitter<any>();

  participantAdded = new Subject<any>();
  participantRemoved = new Subject<any>();
  participantStatusUpdated = new Subject<any>();
  setupDoneTrigger = new Subject<boolean>();
  showRecordingButton: boolean = false;

  constructor(private http: HttpClient) {
    this.publisher = {
      tracks: {
        audio: null,
        audioId: null,
        audioVolume: 100,
        video: null,
        videoId: null,
        screenTrack: null,
        isAudioEnabled: false,
        isVideoEnabled: false,
      },
      client: null,
      screenClient: null,
      isJoined: false,
      isScreenJoined: false,
      isScreenShared: false,
    };
    this.setupDone = false;
  }

  async initSession() {
    AgoraRTC.setLogLevel(0);
    await this.getDevices();

    const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
    const screenClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });

    this.publisher = {
      tracks: {
        audio: null,
        audioId: '',
        audioVolume: 100,
        video: null,
        videoId: '',
        screenTrack: null,
        isAudioEnabled: false,
        isVideoEnabled: false,
      },
      client,
      screenClient,
      isJoined: false,
      isScreenJoined: false,
      isScreenShared: false,
    };

    this.publisher.client
      .enableDualStream()
      .then(() => {
        this.publisher.client.setLowStreamParameter({
          width: 320,
          height: 180,
          framerate: 15,
          bitrate: 140,
        });
        console.log('DUAL STREAM ENABLED');
      })
      .catch(() => console.log('DUAL STREAM UNAVAILABLE'));
  }

  async createAudioTrack(deviceId: string) {
    if (!this.publisher.tracks.audio) {
      try {
        if (!deviceId) {
          deviceId = this.audioDevices[0].deviceId;
        }
        let track = await AgoraRTC.createMicrophoneAudioTrack({
          microphoneId: deviceId,
          AEC: true, // acoustic echo cancellation
          encoderConfig: 'speech_standard',
        });
        this.publisher.tracks.audioVolume = 100;
        this.publisher.tracks.audioId = deviceId;
        this.publisher.tracks.audio = track;
        this.publisher.tracks.isAudioEnabled = true;
      } catch (error) {
        console.log(error, 'error while getting audio track');
        throw error;
      }
    }
  }

  async createVideoTrack(deviceId: string) {
    if (!this.publisher.tracks.video) {
      try {
        if (!deviceId) {
          deviceId = this.videoDevices[0].deviceId;
        }
        let track = await AgoraRTC.createCameraVideoTrack({
          cameraId: deviceId,
          encoderConfig: '480p_1',
          optimizationMode: 'detail',
        });
        this.publisher.tracks.videoId = deviceId;
        this.publisher.tracks.video = track;
        this.publisher.tracks.isVideoEnabled = true;
      } catch (error) {
        console.log(error, 'error while creating video track');
        throw error;
      }
    }
  }

  async createTracks() {
    try {
      await this.createAudioTrack(this.publisher.tracks.audioId);
      await this.createVideoTrack(this.publisher.tracks.videoId);
    } catch (error) {
      throw error;
    }
  }

  async joinChannel() {
    const appId = environment.AGORA_APP_ID;
    const channelId = this.streamUser.channelId;
    const id = String(this.streamUser.streamId);
    const token = this.streamUser.agoraToken?.rtcToken;
    this.setupClientHandlers();

    if (this.userCredentials.role == 'attendee') {
      this.publisher.client.setClientRole('audience');
      this.publisher.tracks.isAudioEnabled = false;
      this.publisher.tracks.isVideoEnabled = false;
    } else {
      this.publisher.client.setClientRole('host');
    }
    // console.log('id nk123 ', id)
    try {
      let uid = await this.publisher.client.join(appId, channelId, token, id);
      // console.log('appId, channelId, token, id nk112233 uid', uid);

      if (this.userCredentials.role != 'attendee') {
        this.addLocalTrack(uid);
        this.publisher.client.enableAudioVolumeIndicator();
      }
    } catch (error) {
      console.log('joinChannel error[agora-rtc.service.ts]', error);
      this.publisher.isJoined = false;
      this.isChannelJoining = false;
    }
  }

  addLocalTrack(id: any) {

    const user = {
      uid: id,
      videoTrack: this.publisher.tracks.video,
      audioTrack: this.publisher.tracks.audio,
      hasAudio: this.publisher.tracks.isAudioEnabled,
      hasVideo: this.publisher.tracks.isVideoEnabled,
      isLocal: true,
    };

    this.participantAdded.next(user);
    setTimeout(() => {
      this.participantStatusUpdated.next(user);
    }, 500);

  }

  getToken(channelId: string, userId: string) {
    return null;
  }

  async leave() {
    await this.publisher.client.leave()
    this.publisher.isJoined = false;
  }

  async shareScreen(userDetails) {
    this.publisher.isScreenShared = true;
    const appId = environment.AGORA_APP_ID;
    const channelId = this.screenUser.channelId;
    const id = String(this.screenUser.streamId);
    const token = this.screenUser.agoraToken.rtcToken;

    try {
      await this.createScreenTrack();
      let uid = await this.publisher.screenClient.join(
        appId,
        channelId,
        token,
        id
      );
      this.screenId = uid;
      await this.publishScreenTrack();
      return uid;
    } catch (error) {
      this.publisher.isScreenShared = false;
      this.screenId = null;
      throw error;
    }
  }

  async stopScreenShare() {
    await this.unpublishScreenTrack();
    this.publisher.isScreenShared = false;
    this.screenId = null;
    await this.setLocalStreamConfig(0);
    setTimeout(async () => {
      await this.publisher.screenClient.leave();
      this.publisher.isScreenJoined = false;
    }, 500);
  }

  async createScreenTrack() {
    try {
      if (!this.publisher.tracks.screenTrack) {
        let track = await AgoraRTC.createScreenVideoTrack({ encoderConfig: '1080p_1', }, 'auto');
        this.publisher.tracks.screenTrack = track;
        this.setupTrackHandlers();
      }
    } catch (error) {
      this.publisher.isScreenShared = false;
      console.log(error.toString());
      throw error;
    }
  }

  async publishScreenTrack() {
    try {
      if (this.publisher.client.remoteUsers.length < 17) {
        await this.publisher.screenClient.publish(this.publisher.tracks.screenTrack);
      }
    } catch (error) {
      this.publisher.isScreenShared = false;
      console.log(error, 'screen publish error');
    }
  }

  async unpublishScreenTrack() {
    if (this.publisher.tracks.screenTrack) {
      await this.publisher.screenClient.unpublish(
        this.publisher.tracks.screenTrack
      );
      this.unregisterTrackHandlers();
      if (this.publisher.tracks.screenTrack instanceof Array) {
        this.publisher.tracks.screenTrack[0]?.close();
        this.publisher.tracks.screenTrack[1]?.close();
      } else {
        this.publisher.tracks.screenTrack?.close();
      }
    }
    this.publisher.tracks.screenTrack = null;
  }

  private setupTrackHandlers() {
    if (this.publisher.tracks.screenTrack) {
      let screenTrack = this.publisher.tracks.screenTrack;
      if (this.publisher.tracks.screenTrack instanceof Array) {
        screenTrack = this.publisher.tracks.screenTrack[0];
      }
      screenTrack.on('track-ended', this.trackHandler);
    }
  }

  private unregisterTrackHandlers() {
    if (this.publisher.tracks.screenTrack) {
      let screenTrack = this.publisher.tracks.screenTrack;
      if (this.publisher.tracks.screenTrack instanceof Array) {
        screenTrack = this.publisher.tracks.screenTrack[0];
      }
      screenTrack.off('track-ended', this.trackHandler);
    }
  }

  trackHandler = () => {
    const emmitData = {
      type: 'screen-share-sttoped',
    };
    this._agora.emit(emmitData);
  };

  async publishTracks() {

    try {
      if (this.userCredentials.role == 'host') {
        await this.publisher.client.publish([this.publisher.tracks.video, this.publisher.tracks.audio]);
      }
    } catch (error) {
      console.log(error, 'stream publish error');
      return;
    }
  }

  async unpublishTracks() {
    let tracksToUnpublish = [];

    if (this.publisher.tracks.audio) {
      tracksToUnpublish.push(this.publisher.tracks.audio);
    }
    if (this.publisher.tracks.video) {
      tracksToUnpublish.push(this.publisher.tracks.video);
    }
    if (tracksToUnpublish) {
      await this.publisher.client.unpublish(tracksToUnpublish);
    }
  }

  async closeTracks() {
    this.publisher.tracks.audio?.stop();
    this.publisher.tracks.audio?.close();
    this.publisher.tracks.video?.stop();
    this.publisher.tracks.video?.close();
  }

  async unpublishTrack(type) {
    let track = null;
    if (type == 'video') {
      track = this.publisher.tracks.video;
    } else {
      track = this.publisher.tracks.audio;
    }
    return new Promise((res, rej) => {
      if (track) {
        this.publisher.client
          .unpublish([track])
          .then((id) => {
            track.stop();
            res(id);
          })
          .catch((err) => {
            console.log(err, 'unpublish error');
            rej(err);
          });
      } else {
        rej({ error: 'could not find video track' });
      }
    });
  }

  async publishTrack(type) {
    let track = null;
    if (type == 'video') {
      track = this.publisher.tracks.video;
    } else {
      track = this.publisher.tracks.audio;
    }
    return new Promise((res, rej) => {
      if (track) {
        this.publisher.client
          .unpublish([track])
          .then((id) => {
            res(id);
            track.stop();
          })
          .catch((err) => {
            console.log(err, 'unpublish error');
            rej(err);
          });
      } else {
        rej({ error: 'audio track is not available' });
      }
    });
  }

  onUserPublished = async (user, mediaType) => {

    if (user.uid != this.screenId) {
      try {
        await this.publisher.client.subscribe(user, mediaType);
      } catch (error) {
        console.log(error, 'error in subscribing event ');
      }

      if (mediaType === 'audio') {
        user.audioTrack.play();
      } else {
        setTimeout(() => {
          this.participantStatusUpdated.next(user);
        }, 1000);

      }
    }
  };

  onUserUnpublished = async (user, mediaType) => {
    if (user.uid != this.screenId) {
      await this.publisher.client.unsubscribe(user, mediaType);
      this.participantStatusUpdated.next(user);
    }
  };

  onUserJoined = async (user) => {
    // triggers when host join the channel
    if (user.uid != this.screenId) {
      this.participantAdded.next(user);
    }
  };

  onUserLeft = async (user, reason) => {
    if (user.uid != this.screenId) {
      // triggers when host join the channel
      if (reason == 'Quit') {
        // when user left the channel
      }

      if (reason == 'ServerTimeOut') {
        // when user dropped off
      }

      this.participantRemoved.next(user);
    }
  };

  networkQualityHandler = async (stats) => {
    // network stats
    let emitData = { type: 'network-quality', stats };
    this._agora.emit(emitData);
  };

  volumeIndicatorHandler = async (volumes) => {
    // volume stats
    volumes.forEach((volume, index) => {
      let id = volume.uid;
      let level = volume.level;
      if (volume.level > this.THRESHOLD_VOLUME) {
        let data = { id, level };
        let emitData = { type: 'volume-indicator', data };
        this._agora.emit(emitData);
      }
    });
  };

  unregisterCallbacks() {
    this.publisher.client.off('user-published', this.onUserPublished);
    this.publisher.client.off('user-unpublished', this.onUserUnpublished);
    this.publisher.client.off('user-joined', this.onUserJoined);
    this.publisher.client.off('user-left', this.onUserLeft);
    this.publisher.client.off('network-quality', this.networkQualityHandler);
    this.publisher.client.off('volume-indicator', this.volumeIndicatorHandler);
  }

  public setupClientHandlers() {
    this.publisher.client.on('user-published', this.onUserPublished);
    this.publisher.client.on('user-unpublished', this.onUserUnpublished);
    this.publisher.client.on('user-joined', this.onUserJoined);
    this.publisher.client.on('user-left', this.onUserLeft);
    this.publisher.client.on('network-quality', this.networkQualityHandler);
    this.publisher.client.on('volume-indicator', this.volumeIndicatorHandler);
  }

  // to set remote stream quality
  setRemoteStreamType(userId, type) {
    let flag = 0;
    if (this.publisher.client) {
      if (type == 'low') {
        flag = 1;
      }
      if (type == 'high') {
        flag = 0;
      }
      this.publisher.client.setRemoteVideoStreamType(userId, flag);
    }
  }

  public getDevices() {
    return new Promise((res, rej) => {
      AgoraRTC.getDevices().then(
        (devices) => {
          this.audioDevices = devices.filter((x) => x.kind == 'audioinput');
          this.videoDevices = devices.filter((x) => x.kind == 'videoinput');
          let emitData = {
            type: 'DEVICE_OBTAINED',
            audioDevices: this.audioDevices,
            videoDevices: this.videoDevices,
          };
          res(emitData);
        },
        (error) => {
          if (error.code === 'PERMISSION_DENIED') {
            let emitData = { type: 'DEVICE_PERMISSION_DENIED' };
            rej(emitData);
          }
          console.log(error);
        }
      );
    });
  }

  generateStreamId(type = 'stream') {
    const max = type == 'screen' ? 200000 : 500000;
    const min = type == 'screen' ? 100000 : 200001;

    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  async closeSession() {
    await this.unpublishTracks();
    await this.closeTracks();
    await this.leave();
    if (this.publisher.isScreenShared) {
      await this.stopScreenShare();
    }
  }

  async setLocalStreamConfig(level) {

    if (!this.publisher.tracks.video?.isPlaying) {
      return;
    }

    if (level) {
      // set to lower
      await this.publisher.tracks.video?.setEncoderConfiguration("360p_1");
    } else {
      // set to higher
      await this.publisher.tracks.video?.setEncoderConfiguration("720p");
    }
  }

  setupComplete() {
    this.setupDone = true;
    this.setupDoneTrigger.next(true);
  }

  // recording work =================>
  getHeader() {
    var usernamePassword = `${environment.agoraConfig.customerId}:${environment.agoraConfig.customerCertificate}`;
    // Encode the String
    let encodedString = window.btoa(usernamePassword);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Basic ${encodedString}`,
      skip: 'true',
    });
    return headers;
  }

  acquire(channelName): Observable<any> {
    let body = {
      cname: channelName,
      uid: String(123456789),
      clientRequest: {
        resourceExpiredHour: 1,
        scene: 0,
      },
    };
    const headers = this.getHeader();
    return this.http.post(
      `https://api.agora.io/v1/apps/${environment.agoraConfig.appId}/cloud_recording/acquire`,
      JSON.stringify(body),
      { headers: headers }
    );
  }

  startReccording(channelName, userId, resourceId): Observable<any> {
    this.acquire(channelName).subscribe(res => {
      console.log('acquire ------->>>>>', res);

    });
    return;
    let transcodingConfig = {
      width: 640,
      height: 480,
      fps: 30,
      bitrate: 750,
      mixedVideoLayout: 1, // best fit video
      backgroundColor: '#FF0000',
    };

    let body = {
      cname: channelName,
      uid: String(123456789),
      clientRequest: {
        recordingConfig: {
          maxIdleTime: 30,
          streamTypes: 2,
          audioProfile: 0,
          channelType: 0,
          videoStreamType: 0,
          transcodingConfig: transcodingConfig,
        },
        recordingFileConfig: {
          avFileType: ['hls', 'mp4'],
        },
        storageConfig: environment.storageConfig,
      },
    };

    const headers = this.getHeader();
    return this.http.post(
      `https://api.agora.io/v1/apps/${environment.agoraConfig.appId}/cloud_recording/resourceid/${resourceId}/mode/mix/start`,
      JSON.stringify(body),
      { headers: headers }
    );
  }

  stopRecording(
    channelName,
    userId,
    resourceId,
    recordingSid
  ): Observable<any> {
    let body = {
      cname: channelName,
      uid: String(123456789),
      clientRequest: {
        resourceExpiredHour: 1,
        scene: 0,
      },
    };

    const headers = this.getHeader();
    return this.http.post(
      `https://api.agora.io/v1/apps/${environment.agoraConfig.appId}/cloud_recording/resourceid/${resourceId}/sid/${recordingSid}/mode/mix/stop`,
      JSON.stringify(body),
      { headers: headers }
    );
  }

  setMeetingDetails(details) {
    this.meetingDetails = details;
  }

  setUserCredentials(details) {
    this.userCredentials = details;
    this.showRecordingButton = details.role == 'host'? true : false;
  }

  getUserCredentials() {
    return this.userCredentials;
  }

  setMeetingPreparation(flag) {
    this.meetingPreparation = flag;
  }

  getMeetingPreparation() {
    return this.meetingPreparation;
  }

  setStreamUserDetails(data) {
    this.streamUser = data;
  }

  getStreamUserDetails() {
    return this.streamUser;
  }

  setScreenUserDetails(data) {
    this.screenUser = data;
  }

  getScreenUserDetails() {
    return this.screenUser;
  }
}
