import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
// import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { Media } from 'src/app/shared/_classes/Media';
import { AuthService } from 'src/app/services/auth/auth-service.service';
import { MeetingService } from 'src/app/services/meeting/meeting.service';
import { AgoraRtcService } from 'src/app/services/agora/agora-rtc.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-stream-view',
  templateUrl: './stream-view.component.html',
  styleUrls: ['./stream-view.component.scss']
})
export class StreamViewComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() media: Media;
  private sub: Subscription;
  @ViewChild('streamContainer') element: ElementRef;

  userName: string = 'user';
  displayName: string;
  roleId: string;
  isScreenStream: boolean = false;
  image: string;
  elementId: string;
  isTalking: boolean = false;
  constructor(
    private authService: AuthService,
    private meetingService: MeetingService,
    public agoraRTC: AgoraRtcService,
    public toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.setUserData();
    this.media.isTalking$.subscribe(state => {
      this.isTalking = state;
    });
  }

  setUserData() {
    const userId = this.media.id;
    this.elementId = `video-${userId}`
    const userData = this.authService.getAuthData();
    const options = { meetingId: this.agoraRTC.meetingDetails.channelId, userId };

    if (userId && userData) {
      this.userName = this.media.userDetails.name || 'user';
      this.displayName = this.media.userDetails.name || 'user';
      this.image = this.media.userDetails.profilePhoto || '';

      // this.meetingService
      //   .getParticipantByUserId(options)
      //   .then((result: any) => {
      //     if (!result.status) {
      //       this.toastr.error('failed to get participant');
      //     }

      //     const usrDetails = result.data.participant;
      //     this.userName = usrDetails.name || 'user';
      //     this.displayName = usrDetails.name || 'user';
      //     this.image = usrDetails.profilePhoto || '';
      //   });
    }
  }

  ngAfterViewInit(): void {
    this.sub = this.media.mediaStatusChanged
      .pipe(
        tap(() => {
          this.playAll();
        })
      ).subscribe();
  }

  playVideo() {
    const user = this.media.user;
    if (user?.videoTrack) {
      if (this.isScreenStream) {
        user.videoTrack.play(this.element?.nativeElement, { fit: 'contain' });
      } else {
        user.videoTrack.play(this.element?.nativeElement);
      }
    }
  }

  playAll() {
    this.playVideo();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
