<h1 mat-dialog-title>Meeting Url</h1>
<div mat-dialog-content>
  <p class="url-title">Host Url: {{fullHostMeetingUrl}} <button [cdkCopyToClipboard]="fullHostMeetingUrl" mat-button
      class="copy-button">copy</button></p>

  <!-- <mat-icon  [cdkCopyToClipboard]="fullHostMeetingUrl">copy_all</mat-icon> -->
  <p class="url-title">Attendee Url: {{fullAttendeeMeetingUrl}}<button [cdkCopyToClipboard]="fullAttendeeMeetingUrl"
      mat-button class="copy-button">Copy</button></p>

</div>
<div mat-dialog-actions class="control-buttons">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <a mat-button routerLink={{hostMeetingUrl}} [mat-dialog-close]="1">Join as Host</a>
  <a mat-button routerLink={{attendeeMeetingUrl}} [mat-dialog-close]="1">Join as Attendee</a>
</div>