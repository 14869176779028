<mat-toolbar>
    <mat-toolbar-row>
      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
      </button>
      <span>BlueMeet</span>
      <span class="example-spacer"></span>
  
      <ul class="nav-link-container">
        <li *ngIf="auth.userIsLoggedIn">
          <span class="header-user-name">{{this.userData? this.userData.displayName :''}}</span>
        </li>
        <li>
          <button mat-raised-button color="warn" *ngIf="auth.userIsLoggedIn" (click)="logOut()">Logout</button>
        </li>
        <!-- routerLinkActive="active" -->
        <!-- <a routerLink="/login" >Login</a> 
        <a routerLink="/register" >Register</a>  -->
  
      </ul>
    </mat-toolbar-row>
  </mat-toolbar>