<div class="container" *ngIf="!permissionStatus.error && !permissionStatus.loading">
  <div class="mt-card-container">
    <div class="row content-container">
      <div class="col">
        <div class="video-preview-container">
          <div class="fill-parent video-preview-loader d-flex align-items-center justify-content-center">
            <mat-spinner color="primary" mode="indeterminate" *ngIf="videoStatus.loading && !videoStatus.error">
            </mat-spinner>
            <div class="d-flex flex-column align-items-center justify-content-center"
              *ngIf="!videoStatus.loading && videoStatus.error">
              <img src="./image_when_cam_off" class="w-25 img-fluid">
            </div>
          </div>
          <div id="videoPreview" class="fill-parent" style="border-radius: 4px;"></div>
        </div>
      </div>
      <div class="col">
        <div class="setting-container">
          <mat-form-field class="mt-4 w-100" *ngIf="agoraRTC.publisher.tracks.videoId" appearance="outline">
            <mat-label>camera</mat-label>
            <mat-select (selectionChange)="changeStreamSource($event.value, 'video')"
              [(value)]="agoraRTC.publisher.tracks.videoId">
              <mat-option *ngFor="let camera of videoDevices" [value]="camera.deviceId" [matTooltip]="camera.label">
                {{camera.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-divider class="my-1 w-100"></mat-divider> -->
          <mat-form-field class="mt-4 w-100" *ngIf="agoraRTC.publisher.tracks.audioId" appearance="outline">
            <mat-label>audio</mat-label>
            <mat-select (selectionChange)="changeStreamSource($event.value, 'audio')"
              [(value)]="agoraRTC.publisher.tracks.audioId">
              <mat-option *ngFor="let mic of audioDevices" [value]="mic.deviceId" [matTooltip]="mic.label">
                {{mic.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-divider class="my-1 w-100" *ngIf="!isArtist"></mat-divider> -->
          <mat-form-field class="mt-4 w-100" appearance="outline" *ngIf="!isArtist">
            <mat-label>Name</mat-label>
            <input matInput [(ngModel)]="userName" />
          </mat-form-field>
          <!-- <mat-divider class="my-1 w-100"></mat-divider> -->
          <div class="mic-status-progess">
            <mat-icon [color]="audioStatus.error ? 'warn':'primary'" *ngIf="!audioStatus.loading">{{audioStatus.error ?
              'mic_none' : 'settings_voice'}}</mat-icon>
            <mat-progress-bar *ngIf="!audioStatus.error" class="w-100 ml-2" color="primary"
              [mode]="audioStatus.loading ? 'buffer' : 'determinate'" [value]="volumeData.val">
            </mat-progress-bar>
            <span class="mat-warn" *ngIf="audioStatus.error && !audioStatus.loading"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="buttons-container" *ngIf="!permissionStatus.error && !permissionStatus.loading">
      <button mat-raised-button class="join-btn" color="primary" (click)="joinSession()">Join Room</button>
      <button mat-raised-button color="warn" style="margin-right: 20px;" (click)="cancelJoin()">Cancel</button>
    </div>

  </div>
</div>
<div class="d-flex flex-column align-items-center p-5" *ngIf="permissionStatus.error && !permissionStatus.loading">
  <p class="text-center">Please allow access to webcam and microphone.</p>
  <img src="./assets/warning.svg" alt="Warning" class="warning-icon">
</div>