<mat-card
  [formGroup]="chatForm"
  class="chat-controls"
>
  <mat-card-content style="width: 100%;">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center" class="chat-controls-parent">
      <!-- TODO: Better layout of form controls -->
      <mat-form-field color="basic" fxFlex="calc(100%-56px-56px-16px-16px-8px-8px)" class="chat-controls-input-container">
        <textarea
          matInput
          placeholder="Message"
          formControlName="message"
          (keydown.enter)="submit()"
        style="height: 20px;"
        ></textarea>
      </mat-form-field>

      <button
        mat-mini-fab
        color="primary"
        (click)="submit()"
        [disabled]="!chatForm.get('message').value"
        style="margin-right:7px"
      >
        <mat-icon>send</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
