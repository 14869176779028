import { Component, OnInit, Inject, OnChanges } from '@angular/core';
import { AuthService } from '../../services/auth/auth-service.service'
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FirebaseService } from 'src/app/services/firebase/firebase.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateMeetingDialogComponent } from 'src/app/components/create-meeting-dialog/create-meeting-dialog.component';
import { environment } from 'src/environments/environment';
import { MeetingService } from 'src/app/services/meeting/meeting.service';
import { AgoraRtcService } from 'src/app/services/agora/agora-rtc.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  loading: boolean = false;
  meetingCode: String = null;
  meetingCodeInput: String = null;
  meetingUrl: String = null;
  googleLoginURL: String = '';
  returnUrl: string = '/';
  constructor(
    public auth: AuthService, private route: ActivatedRoute, private router: Router, private Firebase: FirebaseService,
    public dialog: MatDialog, private MeetingService: MeetingService, private rtc: AgoraRtcService,
    private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => this.returnUrl = params['return'] || '/');
  }

  ngOnChanges(): void {
    console.log('meetingCodeInput--', this.meetingCodeInput);
  }

  async createMetting() {
    try {
      this.loading = true;
      this.MeetingService.createNewMeeting().subscribe((meetingResult: any) => {
        console.log('meeting result --', meetingResult);
        if (meetingResult.status) {
          const data = meetingResult.data;
          this.rtc.setMeetingDetails(data);
          this.meetingUrl = `${environment.meetingBaseUrl}/meeting/${data._id}`;
          this.meetingCode = data.meetingDetails;
          this.loading = false;
          this.openDialog();
        }
      });
    } catch (error) {
      alert('error create meeting check console')
      console.log('createMetting error----------------->>>', error);
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CreateMeetingDialogComponent, {
      width: '900px',
      data: { meetingUrl: this.meetingUrl, meetingCode: this.meetingCode },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  async joinMetting() {
    this.router.navigate(['/meeting', this.meetingCodeInput]);
  }

  async signInWithGoogle() {
    const result: any = await this.auth.signInWithGoogle();

    if (result.status) {
      this.auth.updateUserData(result.data);
      this.router.navigate([this.returnUrl]);
    } else {
      this.toastr.warning(result.error.message);
    }
  }

}
