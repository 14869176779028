import { Component, OnInit,Output,EventEmitter, Input } from '@angular/core';
import { AgoraRtcService } from 'src/app/services/agora/agora-rtc.service';
@Component({
  selector: 'meeting-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit {
  // TODO: we will do setup to use all parent variables
  isScreenShared = false;
  isLocalScreen = false;
  isGalleryView = false;
  newMessageCount = 0;
  chatOpened = false;
  @Input() isRecording: boolean;
  @Output() muteStream = new EventEmitter<string>();
  @Output() handleScreenShare = new EventEmitter<void>();
  @Output() handleGridView = new EventEmitter<void>();
  @Output() toggleSideSection = new EventEmitter<string>();
  @Output() closeSession = new EventEmitter<void>();
  @Output() handleRecording = new EventEmitter<void>();
  constructor(public agoraRTC: AgoraRtcService) {
  }

  ngOnInit(): void {
  }
  _muteStream(type) {
    console.log('_muteStream', type);
    this.muteStream.emit(type);
  }
  _handleScreenShare() {
    console.log('_handleScreenShare');
    this.handleScreenShare.emit();
  }
  _handleGridView() {
    console.log('_handleGridView');
    this.handleGridView.emit();
   }
  _toggleSideSection(type) {
    console.log('toggleSideSection--',type);
    this.toggleSideSection.emit(type);
  }
  _closeSession() {
    console.log('_closeSession');
    this.closeSession.emit();
  }
  _handleRecording() {
    console.log('_handleRecording');
    this.handleRecording.emit();
    // this.isRecording = !this.isRecording;
  }
}
