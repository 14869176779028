import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth-service.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  userName;
  userData;
  constructor(public auth: AuthService, private router: Router) {
    // this.notification()

  }

  logOut() {
    this.auth.signOut();
    this.userName = null;
  }
  ngOnInit(): void {
    // this.Auth.user$.subscribe((userData) => {
    //   console.log('HeaderComponent -res', userData)
    //   if (userData && userData.displayName) {
    //     this.userName = userData.displayName;
    //     this.isloggedIn = true;
    //   } else {
    //     this.userName = null;
    //     this.isloggedIn = false;
    //   }
    // }, (err) => console.log('HeaderComponent err', err))

    // var classData=JSON.parse(this.storage.secureStorage.getItem('loginData'));
    // if(classData != null){
    //   this.userName  = classData.userName;
    // }
    // else{
    //   this.userName='';
    // }

    // localStorage.clear();
    // console.log("localstorage loginData====>",this.storage.secureStorage.getItem('loginData'));
    // if(this.storage.secureStorage.getItem('loginData') != null){
    //   console.log(this.isloggedIn)
    //   this.isloggedIn == true
    // }

    if (this.auth.isLoggedIn()) {

      this.userData = this.auth.getAuthData();
      this.userData = this.userData ? this.userData.user : null;
      // console.log('get auth data nk auth112233 ',this.Auth.getAuthData());

    }
  }
}
